<template>
    <div>
        <b-modal id="popup-alterar-nome"
                 ref="popup-alterar-nome"
                 title="Alterar nome"
                 centered size="sm"
                 hide-footer>

            <form>
                <b-form-group label="Novo nome" label-for="input_nome">
                    <b-form-input type="text" id="input_nome" required v-model="nome"></b-form-input>
                </b-form-group>
            </form>

            <div class="w-100 py-3 text-right">
                <button class="btn-sm btn-secondary mr-2" @click="fecharPopup">CANCELAR</button>
                <button class="btn-sm btn-primary" @click="salvar_nome">SALVAR</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        data() {
            return {
                    nome: '',
            }
        },
        methods: {
            ...mapActions('authentication', ['alterarNome']),
            mostrarPopup() {
                this.$refs['popup-alterar-nome'].show();
            },
            fecharPopup() {
                this.$refs['popup-alterar-nome'].hide();
            },
            salvar_nome() {
                this.alterarNome(this.nome).then(res => {
                    if (res.Sucesso) {
                        this.fecharPopup();
                        this.abrirToastSucesso()
                    }
                    if (!res.Sucesso) {
                        this.msgFalhaSenhaAtual = res.Mensagem;
                    }
                })
            },
            abrirToastSucesso() {
                this.$bvToast.toast('Nome alterado com sucesso!', {
                    title: `Sharkbot`,
                    variant: 'success',
                    autoHideDelay: 5000,
                    solid: true
                })
            }

        },
    }
</script>

<style scoped>
    .erro-input {
        color: red;
        font-size: 12px;
    }
</style>